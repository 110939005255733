import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { Container } from "components/misc/Layouts.js";
import { HashLink } from "react-router-hash-link";

const Row = tw.div`flex flex-col lg:flex-row`;
const Heading = tw(SectionHeading)`text-left lg:text-4xl xl:text-5xl`;
// const Container = tw.div`relative max-w-screen-xl mx-auto my-8 py-16 lg:py-24`
// const Divider = tw.div`mt-16 border-b-2 border-gray-400 w-full max-w-screen-xl mx-auto`;
const ContentWithPadding= tw.div`max-w-screen-xl mx-auto py-20 lg:py-16`;

const PopularPostsContainer = tw.div``;
const PostsContainer = tw.div`mt-12 flex flex-col sm:flex-row sm:justify-between lg:justify-start`;
const Post = tw(
  motion.a
)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 sm:mr-12 lg:mr-8 xl:mr-16`;
const Image = styled(motion.div)((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`,
]);
const Title = tw.h5`mt-6 text-xl font-bold transition duration-300 group-hover:text-primary-500`;
const Description = tw.p`mt-2 font-medium text-secondary-100 leading-loose text-sm`;
// const AuthorInfo = tw.div`mt-6 flex items-center`;
// const AuthorImage = tw.img`w-12 h-12 rounded-full`;
// const AuthorNameAndProfession = tw.div`ml-4`;
// const AuthorName = tw.h6`font-semibold text-lg`;
// const AuthorProfile = tw.p`text-secondary-100 text-sm`;
const PrimaryButton = styled(HashLink)((props) => [
  tw`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 text-sm inline-block mx-auto md:mx-0 `,
  props.buttonRounded && tw`rounded-full`,
]);



export default () => {
  // This setting is for animating the post background image on hover
  const postBackgroundSizeAnimation = {
    rest: {
      backgroundSize: "100%",
    },
    hover: {
      backgroundSize: "105%",
    },
  };
  const primaryButtonText = "Lees meer";
  const primaryButtonUrl = "/services";
  const buttonRounded = true;

  //Recommended: Only 2 Items
  const popularPosts = [
    {
      postImageSrc: "/images/portfolio/Halffapp laptop.png",
      title: "Websites & webshops",
      description:
        "Razendsnelle websites of webshops gemaakt in de nieuwste tech stacks, zoals React en Next Js. Uw website staat klaar voor productie binnen enkele maanden.",
      authorName: "Charlotte Delos",
      authorProfile: "Travel Advocate",
      url: "/services",
    },
    {
      postImageSrc: "/images/portfolio/Webapp kolu laptop.png",
      title: "Platforms & tools",
      description:
        "Webplatforms geïntegreerd in je eigen systeem of CRM. Communiceer met je team, verstuur mails, beheer je planning, allemaal in één platform. Of eerder op zoek naar een webshop? We bespreken met jou de mogelijkheden.",
      authorName: "Adam Cuppy",
      authorProfile: "Vlogger",
      url: "/services",
    },
    {
      postImageSrc:
        "/images/softwarestack2.png",
      title: "Software op maat",
      description:
        "Optimaal gebruik maken van de modernste software stacks, in functie van jouw toepassing. Extra tekst om te garanderen dat de volledige breedte gebruikt wordt.",
      url: "/services",
    },
  ];

  return (
    <Container>
      <ContentWithPadding>
        <Row>
          <PopularPostsContainer>
            <Heading>Onze services</Heading>
            <PostsContainer>
              {popularPosts.map((post, index) => (
                <Post
                  key={index}
                  href={post.url}
                  className="group"
                  initial="rest"
                  whileHover="hover"
                  animate="rest"
                >
                  <Image
                    transition={{ duration: 0.3 }}
                    variants={postBackgroundSizeAnimation}
                    imageSrc={post.postImageSrc}
                  />
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  {/* <AuthorInfo>
                    <AuthorImage src={post.authorImageSrc} />
                    <AuthorNameAndProfession>
                      <AuthorName>{post.authorName}</AuthorName>
                      <AuthorProfile>{post.authorProfile}</AuthorProfile>
                    </AuthorNameAndProfession>
                  </AuthorInfo> */}
                </Post>
              ))}
            </PostsContainer>
          </PopularPostsContainer>
        </Row>
        <PrimaryButton buttonRounded={buttonRounded} to={primaryButtonUrl}>
          {primaryButtonText}
        </PrimaryButton>
      </ContentWithPadding>
    </Container>
  );
};
