import Firebase from "firebase/app";

// Used for a/b testing: read-only database
import "firebase/remote-config";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Performance Monitoring library
import "firebase/performance";

// Add the Performance Monitoring library
import "firebase/firestore";

// config: half.dev
const firebaseConfig = {
    apiKey: "AIzaSyAPU41nSurjRlDe_S3E2q9YiqhbKYCKuTk",
    authDomain: "halff-dev-site.firebaseapp.com",
    projectId: "halff-dev-site",
    storageBucket: "halff-dev-site.appspot.com",
    messagingSenderId: "470435229158",
    appId: "1:470435229158:web:aa59281a1b07a6deda7a27"
};

// db collection
export const firebase = Firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
