import React from "react";
import PageContainer from "usedComponents/PageContainer";
import { Helmet } from "react-helmet-async";
import { Container } from "components/misc/Layouts";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Footer from "usedComponents/commonComponents/Footer.js";
import CalculatorHero from "usedComponents/prijscalculator/CalculatorHero";
// import GetStarted from "usedComponents/commonComponents/GetStartedLight";
import CalculatorSteps from "usedComponents/prijscalculator/CalculatorSteps";
import CalculatorStats from "usedComponents/prijscalculator/CalculatorStats.js";
// import CalculatorPricing from "usedComponents/prijscalculator/CalculatorPricing";
import ServiceFeatures from "usedComponents/commonComponents/ServiceFeatures";
import LeadForm from "usedComponents/LeadForm";

import CalculatorFAQ from "usedComponents/prijscalculator/CalculatorFAQ"

export default () => {
  const enableCookieServices = () => {};

  return (
    <PageContainer disabled>
      <Helmet>
        <title>{"Halff IT consultancy"}</title>
        <meta
          name="description"
          content={
            "Laat een website voor je maken. Software IT consultancy vanuit de startup wereld. Laten we impact maken met software, recruiting, en marketing. Aan een goedkoop tarief."
          }
        />
      </Helmet>
      <Container>
        <CalculatorHero />
        <CalculatorStats />
        <LeadForm />
        <ServiceFeatures />
        <CalculatorSteps />
        {/* <CalculatorPricing /> */}
        <CalculatorFAQ />
        {/* <GetStarted /> */}
        <Footer />
      </Container>
      <CookieConsent
        debug={false}
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="gAnalytics"
        style={{
          background: "rgba(20, 30, 55, 0.95)",
          marginRight: "3vw",
          marginBottom: "3vh",
          width: "80vw",
          maxWidth: "420px",
          borderRadius: "8px",
          right: "0",
          left: "",
          overflow: "hidden",
        }}
        buttonStyle={{
          color: "#141E37",
          background: "#EEE",
          fontSize: "14px",
          borderRadius: "4px",
          marginTop: "0",
          float: "left",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        declineButtonStyle={{
          color: "#EEE",
          background: "rgba(20, 30, 55, 0)",
          fontSize: "14px",
          marginTop: "0",
          float: "right",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        onAccept={enableCookieServices}
      >
        This website uses cookies to analyze our traffic, we use basic Google
        Analytics implementation with anonymized data:{" "}
        <Link id="cookie-link" to="/privacy">
          Privacy Policy
        </Link>
      </CookieConsent>
    </PageContainer>
  );
};
