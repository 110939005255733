import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";
//import { ReactComponent as TwitterIcon} from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon} from "images/linkedin-icon.svg";
//import { ReactComponent as GithubIcon } from "images/github-icon.svg";

// images profile example from kolu 
import jona from "images/team/jona.webp";
import olivier from "images/team/olivier.jfif";
import thomas from "images/team/thomas.jfif";
// import kobe from "images/team/kobe.jfif";
// import kristof from "images/team/kristof.jfif";
import soufyan from "images/team/soufyan.jfif";
// import miguel from "images/team/miguel.jfif";
// import vincent from "images/team/vincent.jfif";


const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

const ProfileThreeColGrid = ({
  heading = "We staan volledig voor u klaar",
  subheading = "Het team",
  description = "Jong, maar niet te onderschatten.",
  cards = [

    {
      imageSrc: olivier,
      position: "Software Developer",
      name: "Olivier Van Bulck",
      links: [
        {
          url: "https://www.linkedin.com/in/oliviervanbulck/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: jona,
      position: "Founder",
      name: "Jona Halffman",
      links: [

        {
          url: "https://www.linkedin.com/in/jona-halffman-178a8099/",
          icon: LinkedinIcon,
        },

      ],
    },
    {
      imageSrc: thomas,
      position: "Full Stack Developer",
      name: "Thomas D'heer",
      links: [
        {
          url: "https://www.linkedin.com/in/thomasdheer/",
          icon: LinkedinIcon,
        },
      ],
    },

    {
      imageSrc: soufyan,
      position: "CMO | Sales Manager",
      name: "Soufyan Hadra",
      links: [
        {
          url: "https://www.linkedin.com/in/soufyan-hadra-117a2a119/",
          icon: LinkedinIcon,
        },
      ],
    },

    // {
    //   imageSrc: kristof,
    //   position: "Junior Software Engineer",
    //   name: "Kristof De Troch",
    //   links: [
    //     // {
    //     //   url: "https://twitter.com",
    //     //   icon: TwitterIcon,
    //     // },
    //     {
    //       url: "https://www.linkedin.com/in/kristof-de-troch-374ba1198/",
    //       icon: LinkedinIcon,
    //     },
    //     // {
    //     //   url: "https://github.com",
    //     //   icon: GithubIcon,
    //     // },
    //   ],
    // },
    // {
    //   imageSrc: kobe,
    //   position: "Student Full Stack Developer",
    //   name: "Kobe Reygel",
    //   links: [
    //     // {
    //     //   url: "https://twitter.com",
    //     //   icon: TwitterIcon,
    //     // },
    //     {
    //       url: "https://www.linkedin.com/in/kobe-reygel-8889431b2/",
    //       icon: LinkedinIcon,
    //     },
    //     // {
    //     //   url: "https://github.com",
    //     //   icon: GithubIcon,
    //     // },
    //   ],
    // },
    // {
    //   imageSrc: miguel,
    //   position: "Student Full Stack Developer",
    //   name: "Miguel Quirijnen",
    //   links: [
    //     // {
    //     //   url: "https://twitter.com",
    //     //   icon: TwitterIcon,
    //     // },
    //     {
    //       url: "https://www.linkedin.com/in/miguelquirijnen/",
    //       icon: LinkedinIcon,
    //     },
    //     // {
    //     //   url: "https://github.com",
    //     //   icon: GithubIcon,
    //     // },
    //   ],
    // },
    // {
    //   imageSrc: " ",
    //   position: "Mobile Applications Developer",
    //   name: "Joris Ignoul",
    //   links: [
    //     // {
    //     //   url: "https://twitter.com",
    //     //   icon: TwitterIcon,
    //     // },
    //     {
    //       url: "https://www.linkedin.com/in/joris-ignoul-41432b18a/",
    //       icon: LinkedinIcon,
    //     },
    //     // {
    //     //   url: "https://github.com",
    //     //   icon: GithubIcon,
    //     // },
    //   ],
    // },
    // {
    //   imageSrc: " ",
    //   position: "Full Stack Developer",
    //   name: "Bram van Bilsen",
    //   links: [
    //     // {
    //     //   url: "https://twitter.com",
    //     //   icon: TwitterIcon,
    //     // },
    //     {
    //       url: "https://www.linkedin.com/in/bram-vanbilsen/",
    //       icon: LinkedinIcon,
    //     },
    //     // {
    //     //   url: "https://github.com",
    //     //   icon: GithubIcon,
    //     // },
    //   ],
    // },
    // {
    //   imageSrc: " ",
    //   position: "Full Stack Developer",
    //   name: "Ruben Lauwaert",
    //   links: [
    //     // {
    //     //   url: "https://twitter.com",
    //     //   icon: TwitterIcon,
    //     // },
    //     {
    //       url: "https://www.linkedin.com/in/ruben-lauwaert-96a5921a6/",
    //       icon: LinkedinIcon,
    //     },
    //     // {
    //     //   url: "https://github.com",
    //     //   icon: GithubIcon,
    //     // },
    //   ],
    // },
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading> }
          {description && <Description>{description}</Description> }
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url}>
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};

export default ProfileThreeColGrid; 