import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

import vercel from "images/vercel.png";
import books from "images/books.png";
// import shopify_template from "images/shopify_template.png";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;


const Row = tw.div`flex items-center justify-center flex-col px-8`

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const WebshopsPortfolio = () => {
  const cards = [
    {
      imageSrc: vercel,
      subtitle: " ",
      title: "Vercel",
      description:
        "Elk project wordt gemaakt in Next Js. Dit garandeert snelheid en allerlei SEO-voordelen. Via hosting op Vercel wordt er gebruik gemaakt van de modernste tools.",
      url: "/contact"
    },

    {
      imageSrc: books,
      subtitle: " ",
      title: "Books",
      description:
        "Afhankelijk van je producten en doelgroep selecteren we de geschikte stijl. Indien gewenst kunnen er digitale tools ingeschakeld worden voor advertenties en marketing.",
      url: "/contact"
    },

    // {
    //   imageSrc: shopify_template,
    //   subtitle: " ",
    //   title: "Digital",
    //   description:
    //     "Elks van deze projecten werden opgebouwd vanuit simpele pagina's. Dankzij te werken met React, blijft alles snel, en is je verbeelding het limiet van wat kan.",
    //   url: "/contact"
    // },
    
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Webshops: design</HeadingTitle>
          <HeadingDescription>
            Bekijk de stijl van onze webshops.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>Maak een afspraak</Link>
              </Details>
            </Card>
          ))}
        </Content>

        <Row>
       
        </Row>
        
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};

export default WebshopsPortfolio;