import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
// import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-3.svg";
import PageContainer from "usedComponents/PageContainer";
import { Helmet } from "react-helmet-async";
import { Container } from "components/misc/Layouts";
// import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Footer from "usedComponents/commonComponents/Footer.js";
import GetStarted from "usedComponents/commonComponents/GetStartedLight";
import LeadForm from "usedComponents/LeadForm";
import HeaderBase from "usedComponents/headers/light.js";
import ServicesGrid from "usedComponents/services/ServicesGrid";

const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8 mx-10`;
const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

const ServicesMainPage = () => {
  const enableCookieServices = () => {};

 
  return (
    <PageContainer disabled>
      <Helmet>
        <title>{"Halff IT consultancy"}</title>
        <meta
          name="description"
          content={
            "Laat een website voor je maken. Software IT consultancy vanuit de startup wereld. Laten we impact maken met software, recruiting, en marketing. Aan een goedkoop tarief."
          }
        />
      </Helmet>
      <Container>
        <Header />
        <ServicesGrid />
        <DecoratorBlob1 />
        <DecoratorBlob2 />
        <LeadForm />
        <GetStarted />
        <Footer />
      </Container>
      <CookieConsent
        location="bottom"
        buttonText="Ik ga akkoord"
        cookieName="GA-halff"
        enableDeclineButton
        declineButtonText="Niet akkoord"
        declineButtonStyle={{ background: "none" }}
        style={{ background: "rgba(36,62,99, 0.95)" }}
        buttonStyle={{
          color: "#fff",
          fontSize: "1rem",
          fontWeight: "700",
          background: "green",
          borderRadius: "0.25rem",
        }}
        expires={150}
        onAccept={enableCookieServices}
      >
        Deze website maakt gebruik van cookies om ons verkeer te analyseren, wij
        gebruiken basis Google Analytics-implementatie met geanonimiseerde
        gegevens:{" "}
        <Link id="cookie-link" to="/privacy">
          Privacy Policy
        </Link>
      </CookieConsent>
    </PageContainer>
  );
};

export default ServicesMainPage;
