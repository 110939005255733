import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "",
  heading = "Veelgestelde vragen",
  description = "Elke vraag is welkom! Als veelzijdig team helpen we je voort met elk probleem. Hier zijn al enkele voorproevers.",
  faqs = [
    {
      question: "Moet ik overschakelen van hosting service ?",
      answer:
        "Neen, dit moet niet. Soms raden we het toch aan, zodat je klanten niet je site verlaten door traag ladende pagina's."
    },
    {
      question: "Ik wil een webshop op mijn website. Kan dit ook?",
      answer:
        "Webshops kunnen we ook. Al hoewel dit vaak duurder uitkomt. Reken ongeveer € 6,000 voor een volledige webshop geïntegreerd met je eigen systeem. Een volledig uniek design zou dit ook duurder doen uitvallen. Eerst even proberen? Dan is het interresanter om te werken met website builders."
    },
    {
      question: "Kan ik mijn site integreren met andere features ?",
      answer:
        "Omdat we exclusief werken met React, en NextJs, kan er met alles verbonden worden. En kunnen vele zaken geautomatiseerd worden voor u en de klant."
    },
    {
      question: "Wat voor marketing zit mee in het pakket?",
      answer:
        "Naast SEO, stellen we de best presterende technieken voor je op. Jij bepaalt het advertentiebudget: Retargeting, landing pages, lead nurturing, Search ads, Facebook ads, Linkedin, recruiting ads, en veel meer."
    },
    {
      question: "Betaal ik voor extra hulp ?",
      answer:
        "Indien u hulp, of kleine upgrades wenst, kunnen deze gratis toegevoegd worden tot wel 2 weken na de definitieve oplevering. Bij het uurtarief betaal je enkel voor werkelijk gepresteerde uren, niet voor meetings."
    },
    {
      question: "Kan ik zelf een andere prijs negotiëren voor iets anders?",
      answer:
        "Dat kan! Neem contact met ons op."
    },
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
