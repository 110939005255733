import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import testFoto from "../../images/logo-full.png";
// import onderzoekFoto from "../../images/onderzoekvoorbeeldfoto.jpg";
// import onderzoekFoto2 from "../../images/onderzoekvoorbeeldfoto2.jpg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

import { ReactComponent as MobileIcon } from "images/smartphone.svg";
import { ReactComponent as BackEndIcon } from "images/backend.svg";
import { ReactComponent as CloudComputingIcon } from "images/cloudcomputing.svg";
import { ReactComponent as IntegrationsIcon } from "images/integrations.svg";

import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";

import HeaderBase from "usedComponents/headers/light.js";
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8 mx-10`;
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between mx-auto pt-8 md:pt-12 pl-20 pb-8 md:pb-12 items-center`;
const Column = tw.div`w-full mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`pl-6 pr-6 md:w-1/2 flex-shrink-0 relative`;
// const Divider = tw.div`my-8 border-b-2 border-gray-300`
const TextColumn = styled(Column)((props) => [
  tw`md:w-2/3 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
  true && tw`pt-12 mt-2 mb-8`,
]);

const DecoratorBlob = tw(
  SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mx-auto md:mx-0 flex-row lg:flex-row max-w-xl lg:max-w-none`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${(props) => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`,
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`;

// const PrimaryButton = styled(PrimaryButtonBase)((props) => [
//   tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
//   props.buttonRounded && tw`rounded-full`,
// ]);

const CustomSoftwareProperties = ({
  subheading = "Onze expertise",
  heading = (
    <>
      Op maat gemaakte <span tw="text-primary-500">software.</span>
    </>
  ),
  description = "Op zoek naar een digitale oplossing voor eender welk probleem? Contacteer ons en we zoeken samen naar een oplossing.",
//   primaryButtonText = "Maak een afspraak",
//   primaryButtonUrl = "contact",
//   imageSrc = testFoto,
//   imageSrc2 = testFoto,
//   imageSrc3 = testFoto,
//   buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = false,
  textOnLeft = true,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: MobileIcon,
      title: "Mobiele apps",
      description:
        "",
      iconContainerCss: tw`bg-yellow-300 text-gray-800`,
    },
    {
      Icon: UserIcon,
      title: "Webplatforms voor intern gebruik",
      description:
        "",
      iconContainerCss: tw`bg-blue-300 text-teal-800`,
    },

    {
      Icon: BackEndIcon,
      title: "Back-end systemen",
      description:
        "",
      iconContainerCss: tw`bg-yellow-300 text-gray-800`,
    },
    {
      Icon: IntegrationsIcon,
      title: "Integraties",
      description:
        "",
      iconContainerCss: tw`bg-blue-300 text-teal-800`,
    },
    {
      Icon: CloudComputingIcon,
      title: "Microservices",
      description:
        "",
      iconContainerCss: tw`bg-yellow-300 text-gray-800`,
    },
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <Header />
      <TwoColumn>
        <ImageColumn>
          <Image
            src={"/images/softwarestack.png"}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
          {showDecoratorBlob && <DecoratorBlob />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureHeadingContainer>
                    <FeatureIconContainer
                      iconFilled={iconFilled}
                      iconRoundedFull={iconRoundedFull}
                      css={feature.iconContainerCss || iconContainerCss}
                    >
                      {<feature.Icon />}
                    </FeatureIconContainer>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                  </FeatureHeadingContainer>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </Feature>
              ))}
            </Features>

            {/* <PrimaryButton
              buttonRounded={buttonRounded}
              as="a"
              href={primaryButtonUrl}
            >
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

export default CustomSoftwareProperties;
