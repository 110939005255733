import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
// import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";

export const HeadingTitle = tw.h2`text-3xl sm:text-4xl font-black tracking-wide text-center`;
export const Subheading = tw.h5`font-bold text-primary-500`;

// import { Link } from "react-router-dom";

// const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-8 font-normal text-lg text-gray-600 text-center max-w-3xl`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`,
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
// const MetaContainer = tw.div`flex items-center`;
// const Meta = styled.div`
//   ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
//   svg {
//     ${tw`w-4 h-4 mr-1`}
//   }
// `;

const Title = tw.h5`leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`;

const ServicesGrid = ({
  subheading = "",
  heading1 = (
    <>
      <span tw="text-primary-500">Op maat gemaakte</span> software oplossingen,
    </>
  ),
  heading2 = (
    <>
      op basis van de <span tw="text-primary-500">modernste</span> technologie.
    </>
  ),
  description = "Wij luisteren naar uw noden en zoeken naar een geschikte oplossing.  Ons jong team werkt efficiënt, is mee met de nieuwste technieken en houdt het zo simpel mogelijk. Na ontwikkeling van het product blijft ons team bovendien makkelijk bereikbaar voor vragen of verder onderhoud.",
}) => {
  const blogPosts = [
    {
      imageSrc:
        "/images/portfolio/Halffapp laptop.png",
      author: "Adam Wathan",
      category: "SEO",
      title: "Websites",
      description:
        "Razendsnelle websites gemaakt in de nieuwste tech stacks, zoals React en Next Js. Uw website staat klaar voor productie binnen enkele maanden.",
      url: "/websites",
    },
    {
      imageSrc: "/images/portfolio/webshop laptop.png",
      author: "Owais Khan",
      category: "Advertising",
      title: "Webshops",
      description:
        "Webshops ontwikkeld rond jouw producten, met veilige betalingsmodules.",
      url: "/webshops",
    },
    {
      imageSrc:
        "/images/recruitingimage.png",
      author: "Steve Schoger",
      category: "Social Media",
      title: "Marketing & recruiting",
      description:
        "Optimaal gebruik maken van digitale pipelines om jouw marketing en recruiting een boost te geven. Beheer van ads, PR campagnes, product outreach, ...",
      url: "/recruitment",
    },
    {
      imageSrc:
        "/images/portfolio/calculator laptop.png",
      author: "Steve Schoger",
      category: "Social Media",
      title: "Prijscalculators",
      description:
        "Versterk je website met een tool waarmee de klant producten op je website kan customizen en automatisch de prijs kan berekenen.",
      url: "/prijscalculator",
    },
    {
      imageSrc:
        "/images/softwarestack2.png",
      author: "Steve Schoger",
      category: "Social Media",
      title: "Custom software tools",
      description:
        "Ben je op zoek naar een andere tool of soort software? Contacteer ons en we bespreken vrijblijvend wat de mogelijkheden zijn.",
      url: "/customsoftware",
    },
    {
      imageSrc:
        "/images/marketanalysis2.png",
      author: "Steve Schoger",
      category: "Social Media",
      title: "Optimalisatie & AI",
      description:
        "Versnel je werkproces op vlak van planning, logistiek, financiën en meer door middel van AI optimalisatie technieken.",
      url: "/contact",
    },
  ];
  return (
    <Content>
      <HeadingInfoContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <HeadingTitle>{heading1}</HeadingTitle>
        <HeadingTitle>{heading2}</HeadingTitle>
        <HeadingDescription>{description}</HeadingDescription>
      </HeadingInfoContainer>
      <ThreeColumn>
        {blogPosts.map((post, index) => (
          <Column key={index}>
            <Card>
              <Image imageSrc={post.imageSrc} />
              <Details>
                {/* <MetaContainer>
                  <Meta>
                    <UserIcon />
                    <div>{post.author}</div>
                  </Meta>
                  <Meta>
                    <TagIcon />
                    <div>{post.category}</div>
                  </Meta>
                </MetaContainer> */}
                <Title>{post.title}</Title>
                <Description>{post.description}</Description>
                <Link href={post.url}>Lees meer</Link>
              </Details>
            </Card>
          </Column>
        ))}
      </ThreeColumn>
    </Content>
  );
};

export default ServicesGrid;
