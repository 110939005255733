import React from "react";
import PageContainer from "usedComponents/PageContainer";
import { Helmet } from "react-helmet-async";
import { Container } from "components/misc/Layouts";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Footer from "usedComponents/commonComponents/Footer.js";
import Hero from "usedComponents/home/Hero.js";
// import Services from "usedComponents/home/DashedBorderSixFeatures.js";
// import Websites from "usedComponents/home/TwoColWithTwoHorizontalFeaturesAndButton.js";
import GetStarted from "usedComponents/commonComponents/GetStartedLight";
// import CalculatorHero from "usedComponents/home/BackgroundAsImage.js";
// import CustomProjecten from "usedComponents/home/PortfolioTwoCardsWithImage";
import { defaultLinks } from "usedComponents/headers/light";
import TechStack from "usedComponents/home/TechStack";
import ServicesSummary from "usedComponents/home/ServicesSummary";
import Testimonials from "usedComponents/websites/Testimonials.js";

// import ThreeColSlider from "usedComponents/home/ThreeColSlider";
import LeadForm from "usedComponents/LeadForm";

//import ThreeColWithSideImage from "usedComponents/home/ThreeColWithSideImage";

export default () => {
  const enableCookieServices = () => {};

  return (
    <PageContainer disabled>
      <Helmet>
        <title>{"Halff IT consultancy"}</title>
        <meta
          name="description"
          content={
            "Laat een website voor je maken. Software IT consultancy vanuit de startup wereld. Laten we impact maken met software, recruiting, en marketing. Aan een goedkoop tarief."
          }
        />
      </Helmet>
      <Container>
        <Hero navLinks={defaultLinks} />
        <ServicesSummary />
        <TechStack />
        <LeadForm />
        <Testimonials/>
        {/* <Services /> */}
        {/* <ThreeColSlider /> */}

        {/* <Websites /> */}
        {/* <GetStarted /> */}
        {/* <CalculatorHero /> */}
        {/* <CustomProjecten /> */}

        {/* <ThreeColWithSideImage /> */}

        

        <GetStarted />
       
        <Footer />
      </Container>
      <CookieConsent
        location="bottom"
        buttonText="Ik ga akkoord"
        cookieName="GA-halff"
        enableDeclineButton
        declineButtonText="Niet akkoord"
        declineButtonStyle={{ background: "none" }}
        style={{ background: "rgba(36,62,99, 0.95)" }}
        buttonStyle={{
          color: "#fff",
          fontSize: "1rem",
          fontWeight: "700",
          background: "green",
          borderRadius: "0.25rem",
        }}
        expires={150}
        onAccept={enableCookieServices}
      >
        Deze website maakt gebruik van cookies om ons verkeer te analyseren, wij
        gebruiken basis Google Analytics-implementatie met geanonimiseerde
        gegevens:{" "}
        <Link id="cookie-link" to="/privacy">
          Privacy Policy
        </Link>
      </CookieConsent>
    </PageContainer>
  );
};
