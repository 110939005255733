import React from "react";
import PageContainer from "usedComponents/PageContainer";
import { Helmet } from "react-helmet-async";
import { Container } from "components/misc/Layouts";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Footer from "usedComponents/commonComponents/Footer.js";
import Header from "usedComponents/headers/light";
import Calendly from "usedComponents/contact/CalendlyComponent";
import ProfileThreeColGrid from "usedComponents/contact/ProfileThreeColGrid.js"; 

export default () => {
  const enableCookieServices = () => {};

  return (
    <PageContainer disabled>
      <Helmet>
        <title>{"Halff IT consultancy | Contact"}</title>
        <meta
          name="description"
          content={
            "Naast ons project van onze startup Halff helpen we je verder met het maken van websites, apps, automatisaties, en moderne marketing technieken."
          }
        />
      </Helmet>
      <Container>
        <Header />
        <Calendly />
        <ProfileThreeColGrid />

          <iframe 
                title="Halff map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.4649356486093!2d4.426752315754374!3d51.08142657956741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3efeeed3fb7b9%3A0x95909cc859ce0efd!2sDoelhaagstraat%2049%2C%202840%20Rumst!5e0!3m2!1sen!2sbe!4v1620040498450!5m2!1sen!2sbe" 
                width="100%"
                height="600"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
                loading="lazy"      
              />

        <Footer />
      </Container>
      <CookieConsent
        debug={false}
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="gAnalytics"
        style={{
          background: "rgba(20, 30, 55, 0.95)",
          marginRight: "3vw",
          marginBottom: "3vh",
          width: "80vw",
          maxWidth: "420px",
          borderRadius: "8px",
          right: "0",
          left: "",
          overflow: "hidden",
        }}
        buttonStyle={{
          color: "#141E37",
          background: "#EEE",
          fontSize: "14px",
          borderRadius: "4px",
          marginTop: "0",
          float: "left",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        declineButtonStyle={{
          color: "#EEE",
          background: "rgba(20, 30, 55, 0)",
          fontSize: "14px",
          marginTop: "0",
          float: "right",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        onAccept={enableCookieServices}
      >
        This website uses cookies to analyze our traffic, we use basic Google
        Analytics implementation with anonymized data:{" "}
        <Link id="cookie-link" to="/privacy">
          Privacy Policy
        </Link>
      </CookieConsent>
    </PageContainer>
  );
};
