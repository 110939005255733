// import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import styled from "styled-components";

const PageContainer =
    window.innerWidth > 1023
        ? AnimationRevealPage
        : styled.div`
              padding: 2rem;
          `;
export default PageContainer;
