import React from "react";
import { css } from "../styles/animation3.css"; //eslint-disable-line

/**
 * Source (CodePen): Judith Neumann, https://codepen.io/judag/pen/PwvPxa
 * @returns 
 */
const AnimationCodeAndView = () => {
  return (
    <div>
      <div class="compu">
        <span class="brow"></span>
        <div class="a">
          <span class="l1"></span>
          <span class="l2"></span>
          <span class="l3"></span>
          <span class="l4"></span>
          <span class="l5"></span>
          <span class="l6"></span>
          <span class="l7"></span>
        </div>
        <div class="b">
          <span class="d1"></span>
          <span class="d2"></span>
          <span class="d3"></span>
          <span class="d4"></span>
          <span class="d5"></span>
        </div>
      </div>
    </div>
  );
};

export default AnimationCodeAndView;
