import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
// import { SectionHeading } from "components/misc/Headings";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto px-0 md:px-4 pb-10 lg:pb-8 pt-10 lg:pt-16`;
// const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
// const Heading = tw(SectionHeading)``;
const Divider = tw.div`my-8 border-b-2 border-gray-300 w-full `;
const TextPlusSliderContainer = tw.div`flex flex-col md:flex-row md:items-center pb-2`;
const SideText = tw.span`text-center md:text-left mr-0 text-lg font-medium md:mr-12 flex-shrink-0`;

const CardSlider = styled(Slider)`
  ${tw`flex flex-nowrap flex-auto items-center justify-between overflow-hidden w-full bg-gradient-to-r from-transparent via-white to-transparent`}
  .slick-track {
    ${tw`flex `}
  }
  .slick-slide {
    ${tw`h-auto flex justify-between mb-1 `}
  }
`;

const Card = tw.div`h-full w-full flex! flex-col max-w-sm relative focus:outline-none mx-6 lg:mx-8 `;

const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}"); `,
  tw`h-24 sm:h-24 bg-center bg-no-repeat bg-contain w-48`,
]);

const TechStackSlider = () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)

  //const [sliderRef, setSliderRef] = useState(null);
  const [setSliderRef] = useState(null);

  const sliderSettings = {
    arrows: false,
    slidesToShow: 4,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 6000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: "images/javascriptLogo.webp",
      title: "Javascript",
    },
    {
      imageSrc: "images/reactLogo.webp",
      title: "ReactJS",
    },
    {
      imageSrc: "images/nextLogo.webp",
      title: "NextJS",
    },
    {
      imageSrc: "images/nodeLogo.webp",
      title: "NodeJS",
    },
    {
      imageSrc: "images/express-original-wordmark.svg",
      title: "Express",
    },
    {
      imageSrc: "images/firebaseLogo.webp",
      title: "Firebase",
    },
    {
      imageSrc: "images/googleCloudLogo.webp",
      title: "Google cloud",
    },
  ];

  return (
    <Container>
      <Content>
        <Divider />
        {/* <HeadingWithControl>
          <Heading>Onze favoriete tools</Heading>
        </HeadingWithControl> */}
        <TextPlusSliderContainer>
          <SideText>Onze toolset:</SideText>
          <CardSlider ref={setSliderRef} {...sliderSettings}>
            {cards.map((card, index) => (
              <Card key={index}>
                <CardImage imageSrc={card.imageSrc} />
              </Card>
            ))}
          </CardSlider>
        </TextPlusSliderContainer>

        <Divider />
      </Content>
    </Container>
  );
};

export default TechStackSlider;
