import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { useEffect } from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import { HelmetProvider } from "react-helmet-async";

/* Inner Pages */
// import TermsOfServicePage from "pages/TermsOfService.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import MainLandingPage from "MainLandingPage.js";
import Websites from "Websites.js";
import Webshops from "Webshops.js";
import PrijsCalculator from "PrijsCalculator.js";
import ContactUsPage from "Contact.js";
import TestPage from "usedComponents/LeadForm";
import ScrollToTop from "usedComponents/helpers/ScrollToTop.js";
import TagManager from "react-gtm-module";

import RecruitmentPage from "RecruitmentPage";
import AIPage from "AIPage";
import CustomSoftwarePage from "CustomSoftwarePage";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ServicesMainPage from "ServicesMainPage";
import Portfolio from "Portfolio";

export default function App() {
  useEffect(() => {
    // Initialize tag manager
    const tagManagerArgs = {
      gtmId: "GTM-MTJ5HHM",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <React.StrictMode>
          <ScrollToTop>
            <Switch>
              <Route path="/" exact component={MainLandingPage} />
              <Route path="/websites" exact component={Websites} />
              <Route path="/webshops" exact component={Webshops} />
              <Route path="/services" exact component={ServicesMainPage} />
              <Route path="/portfolio" exact component={Portfolio} />
              <Route
                path="/prijscalculator"
                exact
                component={PrijsCalculator}
              />
              <Route path="/test" exact component={TestPage} />

              <Route path="/recruitment" exact component={RecruitmentPage} />
              <Route path="/ai" exact component={AIPage} />
              <Route
                path="/customsoftware"
                exact
                component={CustomSoftwarePage}
              />

              <Route path="/contact" exact component={ContactUsPage} />
              <Route path="/privacy" exact component={PrivacyPolicyPage} />
              {/* <Route path="/terms" exact component={TermsOfServicePage} /> */}
            </Switch>
          </ScrollToTop>
        </React.StrictMode>
      </Router>
    </HelmetProvider>
  );
}
