import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import MoneyIcon from "feather-icons/dist/icons/dollar-sign.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;
const Card = styled.div`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100 `}
    img {
      ${tw`w-8 h-8 stroke-current text-purple-600`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

const OverzichtUseCase = ({
  cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Website",
      description: "We hosten uw app enkel op de snelste, en meest vertrouwde aanbieders.",
      url: "/contact"
    },
    {
      imageSrc: MoneyIcon,
      title: "Prijscalculator",
      description: "Een snelle update nodig? Werkt er iets niet? Altijd bereikbaar.",
      url: "/contact"
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Webplatform",
      description: "Pas de huisstijl aan naar je smaak, of bouw samen iets volledig veel mooier.",
      url: "/contact"
    }
  ],
  heading = "Project: Kolu",
  subheading = "Use case",
  description = "Halff en Kolu sloegen de handen in elkaar om het werkprocess van Kolu zoveel mogelijk te automatiseren. De digitalisering vond plaats op drie vlakken, die hieronder gepresenteerd worden.",
  imageContainerCss = null,
  imageCss = ""
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};

export default OverzichtUseCase;