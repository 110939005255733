import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
// import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
// import { Link } from "react-router-dom";

const HeadingTitle = tw.h2`text-3xl sm:text-4xl font-black tracking-wide text-center`;
const Subheading = tw.h5`font-bold text-primary-500`;

// const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const TwoColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/2`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-8 font-normal text-lg text-gray-600 text-center max-w-3xl`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-xl flex flex-col h-full`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-96 rounded rounded-b-none`,
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none  flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
// const MetaContainer = tw.div`flex items-center`;
// const Meta = styled.div`
//   ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
//   svg {
//     ${tw`w-4 h-4 mr-1`}
//   }
// `;

const Title = tw.h5`leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`;

const PortfolioGrid = ({
  subheading = "",
  heading1 = (
    <>
      Overzicht van onze <span tw="text-primary-500">projecten</span>
    </>
  ),
  heading2 = "",
  description = <>Halff bouwt <span tw="text-primary-500 font-semibold">snelle, moderne en mobile-friendly</span> webapplicaties voor allerlei toepassingen. Op basis van jouw wensen komen we tot het resultaat dat het beste bij je past: automatisatie van processen, software op maat, integratie met andere systemen.</>,
}) => {
  const blogPosts = [
    {
      imageSrc: "/images/portfolio/Halffapp laptop.png",
      author: "Adam Wathan",
      category: "SEO",
      title: "Halffapp.com",
      description:
        "Website van Halff, gestyleerd met levendige animaties. Beschikbaar in drie talen.",
      url: "https://halffapp.com/",
    },
    {
      imageSrc: "/images/portfolio/Kolu laptop.png",
      author: "Adam Wathan",
      category: "SEO",
      title: "Kolu.be",
      description:
        "Moderne website op basis van Next Js voor HVAC-bedrijf Kolu, met SEO optimalisatie. Features: zelfplanner voor klanten, productenoverzicht, jobspagina en informatieve wiki.",
      url: "https://www.kolu.be",
    },
    {
      imageSrc: "/images/portfolio/Webapp kolu laptop.png",
      author: "Owais Khan",
      category: "Advertising",
      title: "Kolu webplatform",
      description:
        "Webplatform ontworpen voor het Kolu-team (HVAC bedrijf). Digitalisering van werkproces via integratie met Teamleader, MS Graph, Firebase en Mailchimp: planning en communicatie met verkopers, onderaannemers en leveranciers.",
      url: "",
    },
    // {
    //   imageSrc: "/images/portfolio/oogkliniek laptop.png",
    //   author: "Steve Schoger",
    //   category: "Social Media",
    //   title: "Oogkliniek",
    //   description:
    //     "Modernisering website voor de Oogkliniek, centrum voor oogheelkunde. Features: klanten begeleiden bij bezoek, voorstelling services en informatieve artikels.",
    //   url: "https://www.oogkliniek.be",
    // },
    {
      imageSrc: "/images/portfolio/calculator laptop.png",
      author: "Steve Schoger",
      category: "Social Media",
      title: "Kolu calculator",
      description:
        "Een handgemaakte tool voor Kolu, waarmee een websitebezoeker de prijs van zijn gewenste koelingsinstallatie kan berekenen. Er wordt automatisch een definitieve offerte opgesteld en een verkoper in contact gebracht.",
      url: "https://kolu-calculator.web.app/",
    },
    {
      imageSrc: "/images/portfolio/lsf laptop.png",
      author: "Steve Schoger",
      category: "Social Media",
      title: "LSF Consol",
      description: "Informatieve website voor staalbouwconstructor LSF Consol.",
      url: "https://lsf-website.web.app/",
    },

    {
      imageSrc: "/images/portfolio/quickhomes laptop.png",
      author: "Steve Schoger",
      category: "Social Media",
      title: "Quickhomes",
      description:
        "Website voor staalbouwconstructor Quickhomes, met voorstelling van werkwijze en portfolio.",
      url: "https://quickhomes.be/",
    },
  ];
  return (
    <Content>
      <HeadingInfoContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <HeadingTitle>{heading1}</HeadingTitle>
        <HeadingTitle>{heading2}</HeadingTitle>
        <HeadingDescription>{description}</HeadingDescription>
      </HeadingInfoContainer>
      <TwoColumn>
        {blogPosts.map((post, index) => (
          <Column key={index}>
            <Card>
              {/* <a href={post.url}> */}
              <Image imageSrc={post.imageSrc} />
              {/* </a> */}
              <Details>
                {/* <MetaContainer>
                  <Meta>
                    <UserIcon />
                    <div>{post.author}</div>
                  </Meta>
                  <Meta>
                    <TagIcon />
                    <div>{post.category}</div>
                  </Meta>
                </MetaContainer> */}
                <Title>{post.title}</Title>
                <Description>{post.description}</Description>
                {post.url ? <Link href={post.url}>Bezoek</Link> : null}
              </Details>
            </Card>
          </Column>
        ))}
      </TwoColumn>
    </Content>
  );
};

export default PortfolioGrid;
