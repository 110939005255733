import React from "react";
import PageContainer from "usedComponents/PageContainer";
import { Helmet } from "react-helmet-async";
import { Container } from "components/misc/Layouts";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Footer from "usedComponents/commonComponents/Footer.js";
import WebshopsHero from "usedComponents/webshops/WebshopsHero";
import Services from "usedComponents/webshops/WebshopsServices";
// import CustomProjecten from "usedComponents/webshops/PortfolioTwoCardsWithImage";
import WebshopsFAQ from "usedComponents/webshops/WebshopsFAQ";
// import Pricing from "usedComponents/webshops/TwoPlansWithDurationSwitcher";
//import Testimonials from "usedComponents/webshops/TwoColumnWithImageAndProfilePictureReview.js";
import TechGrid from "usedComponents/webshops/TechGrid.js";
import LeadForm from "usedComponents/LeadForm";

import WebshopsPortfolio from "usedComponents/webshops/WebshopsPortfolio.js";

export default () => {
  const enableCookieServices = () => {};

  return (
    <PageContainer disabled>
      <Helmet>
        <title>{"Halff IT consultancy | webshops"}</title>
        <meta
          name="description"
          content={
            "Naast het grote SaaS AI project van onze startup Halff kunnen we je verder helpen met complexe technologieën voor websites, apps, integraties... en geavanceerde marketing (data-driven, performance marketing en marketing automation).          "
          }
        />
      </Helmet>
      <Container>
        <WebshopsHero />
        <LeadForm />
        <Services />
        {/* <CustomProjecten /> */}
        <WebshopsPortfolio />
        {/* <Testimonials /> */}
        <TechGrid />
        {/* <Pricing /> */}
        <WebshopsFAQ />
        <Footer />
      </Container>
      <CookieConsent
        debug={false}
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="gAnalytics"
        style={{
          background: "rgba(20, 30, 55, 0.95)",
          marginRight: "3vw",
          marginBottom: "3vh",
          width: "80vw",
          maxWidth: "420px",
          borderRadius: "8px",
          right: "0",
          left: "",
          overflow: "hidden",
        }}
        buttonStyle={{
          color: "#141E37",
          background: "#EEE",
          fontSize: "14px",
          borderRadius: "4px",
          marginTop: "0",
          float: "left",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        declineButtonStyle={{
          color: "#EEE",
          background: "rgba(20, 30, 55, 0)",
          fontSize: "14px",
          marginTop: "0",
          float: "right",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        onAccept={enableCookieServices}
      >
        This website uses cookies to analyze our traffic, we use basic Google
        Analytics implementation with anonymized data:{" "}
        <Link id="cookie-link" to="/privacy">
          Privacy Policy
        </Link>
      </CookieConsent>
    </PageContainer>
  );
};
