import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

//import Hero from "components/hero/BackgroundAsImage.js";
import MarketingServices from "usedComponents/recruitment/MarketingServices";
import RecruitMainFeature from "usedComponents/recruitment/RecruitMainFeature.js";
import Header from "usedComponents/headers/light";
import Footer from "usedComponents/commonComponents/Footer.js";
// import Pricing from "./usedComponents/recruitment/RecruitPricing";
import RecruitSteps from "usedComponents/recruitment/RecruitSteps";
import LeadForm from "usedComponents/LeadForm";

const RecruitmentPage = () => (
  <AnimationRevealPage>
    <Header />
    {/* <Hero /> */}
    <RecruitMainFeature />
    <MarketingServices />
    <LeadForm />
    {/* <MainFeature2 /> */}
    {/* <Portfolio />
    {/* <Blog /> */}
    <RecruitSteps />
    {/* <Pricing /> */}
    {/* <ContactUsForm /> */}
    <Footer />
  </AnimationRevealPage>
);

export default RecruitmentPage;
