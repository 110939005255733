import React, { useState, useEffect } from "react";
import Confetti from 'react-dom-confetti';
import { db } from '../firebase'

import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

// SVG icon checkboxes
import { ReactComponent as WebsiteIcon } from "../images/checkboxes/website.svg";
import { ReactComponent as CustomIcon } from "../images/checkboxes/custom.svg";
import { ReactComponent as MobileIcon } from "../images/checkboxes/mobile.svg";
import { ReactComponent as AndersIcon } from "../images/checkboxes/anders.svg";

// https://css-tricks.com/the-magic-of-react-based-multi-step-forms/

const confettiConfig = {
  angle: "98",
  spread: "276",
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: "1",
  width: "5px",
  height: "6px",
  perspective: "672px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const ReactConfetti = tw(Confetti)`absolute top-1/2 left-1/2`

/**
 * Styling
 */
/* step 0 */

const Fieldset = tw.fieldset`flex flex-wrap justify-center mx-auto max-w-128`;
const Checkbox = tw.div`m-2`;
const CheckboxInput = styled.input`
  // Code to hide the input
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:checked + .checkbox-tile {
    border-color: rgba(100, 21, 255);
    box-shadow: 0 5px 10px rgba(#000, 0.1);
    color: rgba(100, 21, 255);
    &:before {
      transform: scale(1);
      opacity: 1;
      background-color: rgba(100, 21, 255);
      border-color: rgba(100, 21, 255);
    }

    .checkbox-icon,
    .checkbox-label {
      color: rgba(100, 21, 255);
    }
  }

  &:focus + .checkbox-tile {
    border-color: rgba(100, 21, 255);
    box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #b5c9fc;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const CheckboxTile = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 11rem;
  min-height: 9rem;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(#000, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &:hover {
    border-color: rgba(100, 21, 255);
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const CheckboxIcon = styled.span`
  transition: 0.375s ease;
  color: #494949;
  padding-bottom: 0.5rem;
  svg {
    width: 4rem;
    height: 4rem;
  }
`;

const CheckboxLabel = styled.span`
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
`;

/* step 1 */
const TextArea = tw.textarea`resize-none rounded-md h-32 px-3 py-3 border relative bg-white bg-white outline-none hocus:ring hocus:shadow-md w-full`;

/* step 2 */
const SelectWrapper = tw.div`relative inline-block w-full text-gray-700 justify-center mx-auto`;
const SelectMain = tw.select`w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none outline-none hocus:shadow-md`;
const SelectArrow = tw.div`absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none`;

/* step 3 */
const SliderInput = tw.input`rounded-lg overflow-hidden appearance-none bg-gray-200 h-4 w-full`;
const SliderValue = tw.div`rounded-md mx-auto w-24 my-4 border px-1 py-1 text-center text-lg`;

/* step 4 */
const TextInfoWrapper = tw.div`mb-4 mx-4 text-gray-700`;
const TextInfoLabel = tw.label`block mb-1`;
const TextInfoInput = tw.input`w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-700 rounded-lg focus:shadow-outline`;
const TextInfoHelp = tw.span`text-xs text-green-700`;

// https://tailwind-starter-kit.vercel.app/docs/forms

/* main */
const Title = tw.p`flex flex-wrap justify-center mx-auto max-w-5xl mb-8 text-3xl sm:text-5xl font-black tracking-wide text-center font-bold text-secondary-500 text-center`;

const NextButton = tw.button`flex w-128 mt-8 bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 mx-auto justify-center rounded relative`;

const ProgressBarWrap = tw.div`mt-8 justify-center mx-auto h-4 relative max-w-128 rounded-full overflow-hidden`;
const ProgressBarBg = tw.div`w-full h-full bg-gray-200 absolute`;
const ProgressBar = tw.div`transition-all ease-out duration-1000 h-full bg-primary-500 relative`;
const Form = tw.form`justify-center mx-auto my-24`;

const ErrorTitle = tw.p`flex flex-wrap justify-center mx-auto max-w-128 my-8 text-lg font-bold text-red-500 text-center`;
const SummaryHeading = tw.p`flex flex-wrap justify-center mx-auto max-w-128 my-8 text-lg font-bold  text-center`;
const SummaryText = tw.p`flex flex-wrap justify-center mx-auto max-w-128 my-8 text-base text-center`;

/**
 * Main component
 */

export default () => {
  /**
   *  React hook forms
   */
  const defaultCheckboxes = [
    {
      id: 0,
      value: "Websites en webshops",
      icon: <WebsiteIcon />,
    },
    {
      id: 1,
      value: "Software ontwikkeling",
      icon: <CustomIcon />,
    },

    {
      id: 2,
      value: "Mobile ontwikkeling",
      icon: <MobileIcon />,
    },

    {
      id: 3,
      value: "Iets anders",
      icon: <AndersIcon />,
    },
  ];

  const leadFormValues = [
    {
      name: "Wat is je gsm nummer?",
      value: "phone",
      type: "tel",
      placeholder: "+32 483 54 37 26",
    },
    {
      name: "Wat is je email?",
      value: "email",
      type: "email",
      placeholder: "name@email.com",
    },
    {
      name: "Wat is je website?",
      value: "website",
      type: "email",
      placeholder: "halffapp.com",
    },
    {
      name: "Wat is je naam?",
      value: "name",
      type: "text",
      placeholder: "",
    },
  ];

  const handleChangeBox = (event) => {
    // updating an object instead of a Map
    const newChecked = { [event.target.value]: true };
    setChecked(newChecked);
    // const value = event.target.value; // meerdere mogelijkheden?
    // const newChecked = checked
    // newChecked[value] = !checked.value;
    // setChecked(newChecked)
  };

  const handleChangeSlide = (event) => {
    setSlider(event.target.value);
  };

  const handleValidateAll = (event) => {
    switch (step) {
      case 0:
        if (checked && Object.keys(checked).length === 0) setError("Gelieve een optie te selecteren.");
        else {
          setError(null);
          if (step <= 5) setStep(step + 1);
        }
        break;
      case 1:
        if (opdracht === "") setError("Gelieve een beschrijving te geven.");
        else {
          setError(null);
          if (step <= 5) setStep(step + 1);
        }
        break;
      case 2:
        if (timing == null) setError("Selecteer iets");
        else {
          setError(null);
          if (step <= 5) setStep(step + 1);
        }
        break;
      case 3:
        setError(null);
        if (step <= 5) setStep(step + 1);
        break;
      case 4:
        if (
          contact.phone === "" ||
          contact.email === "" ||
          contact.website === "" ||
          contact.name === ""
        ){
          setError("Alle velden zijn vereist.");
        }else {
          setError(null);
          if (step <= 5) setStep(step + 1);
          addToFirestore();
        }
        break;
      default:
        // submit
        // congrats!
        break;
    }
  };

  function addToFirestore() {
    const currentdate = new Date(); 
    const datetime = (currentdate.getDate() + "-"
                    + (currentdate.getMonth()+1)  + "-"
                    + currentdate.getFullYear() + "T"  
                    + currentdate.getHours() + ":"
                    + (currentdate.getMinutes().toString().length === 2 ? '' : '0') 
                    + currentdate.getMinutes() + ":"
                    + (currentdate.getSeconds().toString().length === 2 ? '' : '0') 
                    + currentdate.getSeconds()).toString();

    db.collection("leads").doc(datetime).set({
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        website: contact.website,
        subject: Object.keys(checked)[0],
        timing: timing,
        description: opdracht
      })
          .then(() => {
              console.log("Document successfully written!");
          })
          .catch((error) => {
              console.error("Error writing document: ", error);
          });
  }

  const [checked, setChecked] = useState({});
  // meerdere mogelijkheden?
  // const [checked, setChecked] = useState({
  //   "Websites en webshops": false,
  //   "Software ontwikkeling": false,
  //   "Mobile ontwikkeling": false,
  //   "Iets anders": false  });
  const [slider, setSlider] = useState(8000);
  const [opdracht, setOpdracht] = useState("");
  const [timing, setTiming] = useState("Deze maand nog");

  // contact
  const [contact, setContact] = useState({
    phone: "",
    email: "",
    website: "",
    name: "",
  });

  const [step, setStep] = useState(0);

  const [error, setError] = useState(null);

  const [confetti, setConfetti] = useState(false);

  useEffect(() => {
    console.log("checkedItems: ", checked);
  }, [checked]);

  useEffect(() => {
    console.log("step: ", step);
  }, [step]);

  /**
   *  Multi-step form state
   */

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <Form>
      {/* Step 0 */}
      {step === 0 && (
        <>
          <Title>Waar kunnen we mee helpen?</Title>
          <Fieldset>
            {defaultCheckboxes.map((checkbox) => {
              return (
                <Checkbox className="checkbox">
                  <label className="checkbox-wrapper">
                    <CheckboxInput
                      checked={checked[checkbox.value]}
                      type="checkbox"
                      className="checkbox-input"
                      onChange={handleChangeBox}
                      value={checkbox.value}
                    />
                    <CheckboxTile className="checkbox-tile">
                      <CheckboxIcon className="checkbox-icon">
                        {checkbox.icon}
                      </CheckboxIcon>
                      <CheckboxLabel className="checkbox-label">
                        {checkbox.value}
                      </CheckboxLabel>
                    </CheckboxTile>
                  </label>
                </Checkbox>
              );
            })}
          </Fieldset>
        </>
      )}

      {/* Step 1 */}
      {step === 1 && (
        <>
          <Title>Wat zijn je doelen of vereisten voor het project?</Title>
          <Fieldset>
            <TextArea
              value={opdracht}
              onChange={(e) => setOpdracht(e.target.value)}
              placeholder="Mijn software kan ...  Het idee lijkt een beetje op..."
            />
          </Fieldset>
        </>
      )}

      {step === 2 && (
        <>
          <Title>Wanneer wil je van start?</Title>
          <Fieldset>
            <SelectWrapper>
              <SelectMain onChange={(e) => setTiming(e.target.value)}>
                <option>Deze maand nog</option>
                <option>Over 1 maand</option>
                <option>Over 2 maanden</option>
                <option>Over 3 maanden</option>
                <option>Over 4 maanden</option>
                <option>Over 5 maanden</option>
                <option>Over 6 maanden</option>
                <option>Over 7+ maanden</option>
              </SelectMain>
              <SelectArrow>
                <svg
                  tw="w-4 h-4 fill-current"
                  class="w-4 h-4 fill-current"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </SelectArrow>
            </SelectWrapper>
          </Fieldset>
        </>
      )}

      {step === 3 && (
        <>
          <Title>Wat is je budget?</Title>
          <Fieldset>
            <SliderValue>
              {"€ " + Number(slider).toLocaleString("be-BE")}
            </SliderValue>

            <SliderInput
              type="range"
              min="500"
              max="60000"
              step="500"
              defaultValue={slider}
              onChange={handleChangeSlide}
            />
          </Fieldset>
        </>
      )}

      {step === 4 && (
        <>
          <Title>Hoe kunnen we je contacteren?</Title>
          <Fieldset>
            {leadFormValues.map((formValue) => {
              return (
                <TextInfoWrapper>
                  <TextInfoLabel for={formValue.value}>
                    {formValue.name}
                  </TextInfoLabel>
                  <TextInfoInput
                    onChange={(e) => {
                      contact[formValue.value] = e.target.value;
                      setContact(contact);
                    }}
                    type={formValue.type}
                    id={formValue.value}
                    name={formValue.value}
                    aria-describedby={formValue.value + "Help"}
                    placeholder={formValue.placeholder}
                  ></TextInfoInput>
                  <TextInfoHelp id={formValue.value + "Help"}></TextInfoHelp>
                </TextInfoWrapper>
              );
            })}
          </Fieldset>
        </>
      )}

      {step === 5 && (
        <>
          <Title>Bedankt!</Title>
          <SummaryHeading>Uw wensen zijn goed aangekomen. We contacteren u spoedig!</SummaryHeading>
          <SummaryHeading>Uw gegevens: </SummaryHeading>
          <SummaryText>{contact.name}<br></br>
          {contact.phone}<br></br>
          {contact.email}<br></br>
          {contact.website}</SummaryText>
          <ReactConfetti
            ref={() => { setConfetti(true) }}
            active={confetti}
            config={confettiConfig}
          />
        </>
      )}

      <ErrorTitle>{error}</ErrorTitle>

      {step < 5 && (
        <NextButton
          type="button"
          onClick={(e) => {
            console.log({
              checked,
              slider,
              opdracht,
              contact,
              timing,
            });
            handleValidateAll(e);
          }}
        >
          {step < 4 ? 'Volgende' : 'Verzenden'}
        </NextButton>
      )}

      {step < 5 && (<ProgressBarWrap>
        <ProgressBarBg></ProgressBarBg>
        <ProgressBar style={{ width: `${step * 20 + 20}%` }} />
      </ProgressBarWrap>)
      }
    </Form>
  );
};
