import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

//import Header, { LogoLink, DesktopNavLinks, NavLink} from "../headers/light.js";
// import AnimateColumn from "../animation";
import AnimateColumn2 from "../animation2";

import HeaderBase from "../headers/light.js";

const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8 mx-10`;
const Container = tw.div`relative -mx-8 -mt-8 max-w-screen-2xl mx-auto`;
const Column = tw.div`lg:w-1/2`;
const TwoColumn = tw.div`md:pl-8 flex flex-col lg:flex-row `;
const LeftColumn = tw(Column)`ml-8 mr-8 xl:pl-10 py-8`;
const MediaColumn = tw(
    Column
)`ml-auto lg:mr-0 relative mt-24 lg:mt-24 lg:ml-32 hidden lg:inline`;
const MediaContainer = tw.div`relative z-40 transform`;
// const RightColumn = tw.div`h-96 h-auto ml-8
// lg:w-1/2 
// flex-1 
// -mb-72
// md:mb-0 inline-block

// mt-6 pt-0 px-16
// sm:mt-6 sm:pt-3 
// md:mt-12 md:pt-6
// lg:mt-24 lg:pt-12`;

const Content = tw.div`mt-16 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

const Hero = ({
  navLinks,
  heading = (
    <>
      Creatieve,
      <span tw="text-primary-500"> digitale</span>
      <wbr />
      <br /> oplossingen
    </>
  ),
  description = (
    <>
      Bij Halff kunnen we u helpen met websites, mobiele applicaties of andere
      gewenste tools.
    </>
  ),
  primaryActionUrl = "/contact",
  primaryActionText = "Contacteer ons",
}) => {
  return (
    <Container>
      <Header links={navLinks} collapseBreakpointClass="lg" />
      <TwoColumn>
        <LeftColumn>
          {/* <StyledHeader links={navLinks} collapseBreakpointClass="sm" /> */}
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <Actions>
              <a href={primaryActionUrl} className="action primaryAction">
                {primaryActionText}
              </a>
              {/* <a href={secondaryActionUrl} className="action secondaryAction">
                {secondaryActionText}
              </a> */}
            </Actions>
          </Content>
        </LeftColumn>
        <MediaColumn>
          <MediaContainer>
            <AnimateColumn2></AnimateColumn2>
          </MediaContainer>
        </MediaColumn>
      </TwoColumn>
    </Container>
  );
};

export default Hero;
